<template>
  <el-menu
    :mode="config.mode"
    :background-color="config.backgroundColor"
    :text-color="config.textColor"
    :active-text-color="config.activeColor"
    :collapse="collapse"
    :unique-opened="config.uniqueOpened"
    :router="config.router"
    :default-active="path"
    @select="closeSidebar">
    <div>
      <el-row class="logos">
        <img src="@/assets/PetNet-logo.png" alt="">
      </el-row>
    </div>
    <template v-for="(route, index) in routes">
      <menu-group v-if="route.children" :key="index" :route="route"></menu-group>
      <menu-item v-else :key="index" :route="route" ></menu-item>
    </template>
  </el-menu>
</template>

<script>
import MenuGroup from './components/MenuGroup'
import MenuItem from './components/MenuItem'

export default {
  name: 'Menu',
  components: {
    MenuGroup,
    MenuItem
  },
  props: {
    routes: {
      type: Array,
      default: null
    },
    config: {
      mode: {
        type: String,
        required: true
      },
      background: {
        type: String,
        required: true
      },
      textColor: {
        type: String,
        required: true
      },
      activeColor: {
        type: String,
        required: true
      },
      uniqueOpened: {
        type: Boolean,
        required: true
      },
      router: {
        type: Boolean,
        required: true
      }
    },
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      path: 'null'
    }
  },
  mounted () {
    this.path = this.getActivePath()
  },
  methods: {
    getActivePath () {
      return this.$route.fullPath
    },
    closeSidebar () {
      if (window.innerWidth < 992) {
        this.$store.dispatch('app/toggleSideBar')
      }
    }
  },
  watch: {
    $route () {
      this.path = this.getActivePath()
    }
  }
}
</script>

<style>
  .el-menu:not(.el-menu--collapse) {
    width: 250px;
    color: rgb(255, 255, 255);
  }
</style>

<style lang="scss" scoped>
  .logos {
    display: flex;
    justify-content: center;
    img {
      display: inline;
      margin: 30px 50px 20px;
      width: 150px;
      overflow-x: hidden;
    }
  }
</style>
