<template>
  <div>
    <el-aside :class="{'opened': getSidebarState, 'closed': !getSidebarState, 'is-mobile': checkWindowWidth()}">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <sidebar :routes="routes" :config="config" :collapse="!getSidebarState"/>
      </el-scrollbar>
    </el-aside>
    <div class="mobile-sidebar" :class="{'opened': getSidebarState, 'closed': !getSidebarState, 'show': checkWindowWidth()}" @click="toggleSidebar()"></div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import { filteredRoutes } from '@/router'

export default {
  name: 'Sidebar',
  components: {
    sidebar: Menu
  },
  data () {
    return {
      routes: [],
      config: {
        mode: 'vertical',
        backgroundColor: '#FAFAFA',
        textColor: '#686868',
        activeColor: '#EB2C3E',
        uniqueOpened: true,
        router: true
      },
      windowWidth: window.innerwidth
    }
  },
  computed: {
    getSidebarState () {
      return this.$store.getters.sidebar.opened
    }
  },
  async mounted () {
    this.routes = await filteredRoutes()
    this.onResize()
    if (this.checkWindowWidth()) {
      this.$store.dispatch('app/closeSideBar', true)
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize, true)
    })
  },
  methods: {
    toggleSidebar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    checkWindowWidth () {
      return this.windowWidth < 992
    }
  },
  watch: {
    windowWidth () {
      this.checkWindowWidth()
    }
  }
}
</script>

<style lang="scss">
  .el-aside {
    width: auto !important;
    height: 100%;
    transition: transform .2s;
    &.is-mobile {
      position: absolute;
      z-index: 999;
      height: 100%;
      &.closed {
        transform: translateX(-64px);
      }
      &.opened {
        transform: translateX(0);
      }
    }
    .el-scrollbar {
      height: 100%;
      z-index: 11;
      background-color: #FAFAFA;

      .scrollbar-wrapper {
        overflow-x: hidden;
      }
    }
    .el-menu {
      border: 0;
      span {
        font-size: 0.8rem !important;
      }
    }
  }
  .mobile-sidebar {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 998;
    transition: visibility .2s, opacity .2s;
    &.show {
      display: block;
    }
    &.closed {
      visibility: hidden;
      opacity: 0;
    }
    &.opened {
      visibility: visible;
      opacity: 1;
    }
  }
  .el-aside .el-menu span {
    font-size: 11px !important;
  }
</style>
