<template>
  <el-menu-item :index="route.path">
    <i :class="route.icon"></i>
    <span slot="title">{{ route.name }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    route: {
      type: Object,
      default: null
    }
  }
}
</script>
