<template>
  <header>
    <el-button type="text" class="button toggleSidebar" @click="toggleSidebar"><i class="el-icon-s-fold"></i></el-button>
    <div class="right">
      <!-- <el-button type="text" class="button"><i class="el-icon-search" @click="toggleSearch"></i></el-button>
      <div class="searchbar" :class="{'show': showSearch}">
        <el-select
          class="search"
          v-model="term"
          filterable
          remote
          placeholder="Search"
          :remote-method="remoteMethod"
          :loading="loading"
          @click="toggleSearch"
          @change="change">
          <template v-for="(item, index) in options">
            <el-option
              v-if="!item.item.children"
              :key="index"
              :label="item.item.name"
              :value="item.item.path" />
            <el-option-group
              v-else
              :key="index"
              :label="item.item.name">
              <el-option
                v-for="(child, childIndex) in item.item.children"
                :key="childIndex"
                :label="child.name"
                :value="child.path">
              </el-option>
            </el-option-group>
          </template>
        </el-select>
      </div> -->
      <el-link href="https://docs.google.com/document/d/10jpXf0bkmrxvn7GHKn1JGn8QC9oYscBAkPrm6eVcCg0/edit" target="_blank">User Manual</el-link>
        <el-dropdown trigger="click" class="notification" @command="handleNotifications">
          <el-badge :value="notificationCount" class="item">
            <i class="el-icon-bell"></i>
          </el-badge>
          <el-dropdown-menu slot="dropdown" class="notification-dropdown">
            <div v-if="notifications.length > 0">
              <el-dropdown-item v-for="notification in notifications" :key="notification.id" :command="notification">
                <div class="notification" :class="{'not-read': !parseInt(notification.is_read)}">
                  <h3 class="notification-header">{{notification.header}}</h3>
                  <p class="notification-description">{{notification.description}}</p>
                  <p class="notification-date">{{formatDate(notification.created_at)}}</p>
                </div>
              </el-dropdown-item>
            </div>
            <div v-else>
              <el-empty description="No Notifications Yet!" style="display: flex;align-items: center; justify-content: center;" />
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      <el-dropdown trigger="click" @command="handle">
        <el-avatar shape="circle" size="small" :src="photo"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/profile">Profile</el-dropdown-item>
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { sidebarRoutes } from '@/router'
import Fuse from 'fuse.js'
import { format } from 'date-fns'

export default {
  name: 'MainHeader',
  data () {
    return {
      term: null,
      loading: false,
      showSearch: false,
      routes: sidebarRoutes,
      photo: this.$store.getters.user.photo,
      options: [],
      notifications: [],
      notificationCount: '',
      config: {
        shouldSort: true,
        threshold: 0.0,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          'path',
          'name',
          'meta.title',
          'children.path',
          'children.name',
          'children.meta.title'
        ]
      },
      engine: null
    }
  },
  watch: {
    '$store.getters.user.photo': function (val) {
      this.photo = val
    }
  },
  mounted () {
    this.initFuse()
    this.getNotification()
  },
  methods: {
    toggleSidebar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    toggleSearch () {
      this.showSearch = !this.showSearch
    },
    initFuse () {
      this.engine = new Fuse(this.routes, this.config)
    },
    getNotification () {
      this.$http
        .get(`ess.notification/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.notifications = res.data.body
          const notRead = this.notifications.filter(notif => notif.is_read === '0').length
          this.notificationCount = notRead ? notRead + '' : ''
        })
    },
    remoteMethod (query) {
      if (query !== '') {
        this.options = this.engine.search(query)
      } else {
        this.options = []
      }
    },
    change (path) {
      this.showSearch = false
      this.term = ''
      this.options = []
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    },
    handleNotifications (notification) {
      const form = {
        id: notification.id
      }
      this.$http
        .post('ess.update-notification', form)
        .then(() => {
          this.$router.push(notification.url)
          this.getNotification()
        })
    },
    handle (command) {
      if (command === 'logout') {
        this.logout()
      } else {
        if (command !== this.$route.path) {
          this.$router.push(command)
        }
      }
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    logout () {
      this.$http
        .post('logout')
        .then(() => {
          this.$cookies.remove('token')
          this.$cookies.remove('em_id')
          this.$router.push('/login')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    justify-content: space-between;
    .button, .toggleSidebar {
      color: black;
    }
    .right {
      display: flex;
      .searchbar {
        max-width: 0;
        transition: max-width .5s;
        overflow: hidden;
        &.show {
          max-width: 300px;
          width: 100%;
        }
        .el-select.search >>> input:not(.el-select__input) {
          cursor: text;
          border: 0;
          border-bottom: 1px solid #DCDFE6;
        }
      }
      .el-badge {
        display: flex;
        align-items: center;
      }
      .notification {
        margin-left: 1rem;
      }
      .el-dropdown {
        cursor: pointer;
        margin-left: 1rem;
        display: flex;
        align-items: center;
      }
      .el-dropdown-link {
        color: #409EFF;
      }
    }
  }
</style>
