<template>
  <el-submenu :index="route.path">
    <template slot="title">
      <i :class="route.icon"></i>
      <span slot="title">{{ route.name }}</span>
    </template>
    <el-menu-item-group v-for="(group, index) in groupRoutes" :key="index">
      <span slot="title">{{group.group}}</span>
      <menu-item v-for="(route, index) in group.children" :key="index" :route="route" />
    </el-menu-item-group>
  </el-submenu>
</template>

<script>
import MenuItem from './MenuItem'
export default {
  name: 'MenuGroup',
  components: {
    MenuItem
  },
  props: {
    route: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      groupRoutes: []
    }
  },
  created () {
    const groups = [...new Set(this.route.children.map(item => item.group))]
    const groupRoutes = groups.map(group => {
      return {
        group: group,
        children: this.route.children.filter(item => {
          return item.group === group
        })
      }
    })
    this.groupRoutes = groupRoutes
  }
}
</script>
