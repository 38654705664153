<template>
  <el-row>
    <el-tag
    size="small"
    effect="dark"
    @click="clear">
      Clear Tabs
    </el-tag>
    <el-tag
      v-for="item in history"
      :key="item.name"
      closable
      size="small"
      :effect="$route.path === item.path ? 'dark' : 'plain'"
      :type="$route.path === item.path ? 'success' : 'info'"
      @click="navigate(item)"
      @close="remove(item)">
      {{item.name}}
    </el-tag>
  </el-row>
</template>

<script>
import { history } from '@/router'
export default {
  name: 'Breadcrumbs',
  data () {
    return {
      history: history
    }
  },
  methods: {
    remove (item) {
      this.history.splice(this.history.indexOf(item), 1)
    },
    navigate (item) {
      if (this.$route.path !== item.path) {
        this.$router.push(item.path)
      }
    },
    clear () {
      this.history.splice(this.history)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-row {
    overflow-x: auto;
    .el-tag {
      margin: .3rem;
      border-radius: 0;
      cursor: pointer;
      &--info {
        color: black;
      }
    }
  }
</style>
