<template>
  <el-container class="main-container">
    <sidebar/>
    <el-container>
      <el-header class="main-header">
        <main-header />
      </el-header>
      <div class="breadcrumbs">
        <breadcrumbs />
      </div>
      <el-scrollbar class="scrollbar-wrapper-main">
        <el-main>
          <transition :name="mainRouteOutput" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-scrollbar>
      <!-- <el-footer>
        <main-footer />
      </el-footer> -->
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from '@/layout/Sidebar'
import MainHeader from '@/layout/Header'
import Breadcrumbs from '@/components/Breadcrumbs'
// import MainFooter from '@/layout/Footer'

export default {
  name: 'MainContainer',
  components: {
    Sidebar,
    MainHeader,
    Breadcrumbs
    // MainFooter
  },
  data () {
    return {
      mainRouteOutput: 'main-route-output'
    }
  },
  async mounted () {
    this.getEmployeePhoto()
  },
  methods: {
    getEmployeePhoto () {
      this.$http
        .get(`ess.photo/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.$store.dispatch('user/setEmployeePhoto', `data:image/png;base64,${res.data.epic}`)
        })
    }
  }
}
</script>

<style lang="scss">
  .el-container.main-container {
    height: 100vh;
    .el-header, .el-footer {
      line-height: 60px;
      z-index: 2;
      font-size: 12px;
    }
    .el-header.main-header {
      box-shadow: 0 1px 1px #ebebeb;

      i {
        font-size: 24px;
      }
    }
    .breadcrumbs {
      padding: 0 1rem;
      box-shadow: 0 2px 2px #e7e7e7;
    }
    .scrollbar-wrapper-main {
      flex: 1;
      background-color: #EFF1F4;
      .el-scrollbar__wrap {
        overflow-x: auto;
      }
      .el-main {
        .main-route-output-enter-active, .main-route-output-leave-active {
          transition: opacity .3s ease;
        }
        .main-route-output-enter, .main-route-output-leave-to {
          opacity: 0;
        }
      }
    }
    .el-footer {
      background-color: #2a2f33;
      color: #fff;
    }
  }
</style>
